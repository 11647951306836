<template>
  <!--会员信息-->
  <div style="padding: 20px;">
    <div>
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <span style="font-size: 24px;font-weight: 500;color: #707070;">会员中心</span>
        <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
      </div>
      <!--<el-card shadow="never" style="margin-top: 20px;">-->
      <!-- -->
      <!--</el-card>-->

      <div style="margin-top: 60px;">
        <!--vue绑定背景图片:style="{backgroundImage :`url(${img})`}"-->
        <div class="bg" :style="{backgroundImage :`url(${img})`}">
          <div class="bg_t" style="position: relative;">
            <div style="display: flex;align-items: center;">
              <div>
                <img :src="member_info.facilitator_avatar" alt="" style="width: 130px;height: 130px;border-radius: 50%;">
              </div>
              <div style="margin-left: 40px;">
                <div style="display: flex;align-items: center;">
                  <span style="font-size: 36px;font-weight: 700;color: #994d00;">{{member_info.facilitator_name}}</span>
                  <span style="display: inline-block;background: linear-gradient(132deg,#ff760d 6%, #f69311 59%, #ff802b 95%);border-radius: 8px;padding: 10px 15px 10px 15px;margin-left: 20px;">
                  <span style="font-size: 18px;font-weight: 400;color: #fff5d9;">{{member_info.member_name}}</span>
                </span>
                </div>
                <div style="display: flex;align-items: center; margin-top: 10px;" >
                  <span v-if="member_info.member_name != '' && member_info.member_name != '注册会员'" style="font-size: 20px;font-weight: bold;color: #a66a2e;">您的会员将于{{member_info.overdue_time}}到期</span>
                  <span v-if="member_info.member_name != '' && member_info.member_name != '注册会员'" style="background: #fff4e1;border-radius: 24px;display: inline-block;padding: 8px 15px 8px 15px;margin-left: 30px;cursor: pointer;" @click="ToupgradeMember">
                    <span v-if="member_info.member_name != '' && member_info.member_name != '注册会员'" style="font-size: 16px;font-weight: 500;color: #be9245;">立即续费</span>
                  </span>
                  <span style="background: #fff4e1;border-radius: 24px;display: inline-block;padding: 8px 15px 8px 15px;margin-left: 30px;cursor: pointer;" @click="ToupgradeMember">
                  <span style="font-size: 16px;font-weight: 500;color: #be9245;">升级会员</span>
                </span>
                </div>
              </div>
            </div>
            <img src="@/assets/images/home/yunli/vip.png" alt="" style="position: absolute;top: 0;right: 0;">
          </div>

          <div style="background: #fff5e4;border: 2px solid #fff3e0;border-radius: 24px;padding: 20px;margin-top: 20px;">
            <!--<div class="huiyuan_t">-->
            <!--  <div class="biankuang" style="display: inline-block;width: 230px;margin-right: 30px;">-->
            <!--    <el-select-->
            <!--        v-model="form.authentication_status"-->
            <!--        placeholder="请选择类型"-->
            <!--        class="ele-fluid">-->
            <!--      <el-option label="升级会员" value="1"/>-->
            <!--      <el-option label="待认证" value="2"/>-->
            <!--      <el-option label="=已认证" value="3"/>-->
            <!--    </el-select>-->
            <!--  </div>-->
            <!--  <div class="biankuang" style="display: inline-block;width: 230px;">-->
            <!--    <el-select-->
            <!--        v-model="form.authentication_status"-->
            <!--        placeholder="请选择类型"-->
            <!--        class="ele-fluid">-->
            <!--      <el-option label="vip" value="1"/>-->
            <!--      <el-option label="待认证" value="2"/>-->
            <!--      <el-option label="=已认证" value="3"/>-->
            <!--    </el-select>-->
            <!--  </div>-->
            <!--</div>-->

            <div class="huiyuan_c" style="text-align: center;margin-top: 30px;">
              <img src="@/assets/images/home/yunli/left.png" alt="" style="width: 60px;height: 4px;vertical-align: middle;margin-right: 20px;">
              <span style="font-size: 30px;font-weight: normal;color: #a25200;vertical-align: middle;margin-right: 20px;">会员权益</span>
              <img src="@/assets/images/home/yunli/right.png" alt="" style="width: 60px;height: 4px;vertical-align: middle;">
            </div>

            <div class="huiyuan_b" style="margin-top: 20px;">
              <el-row :gutter="15">
                <el-col :span="4" style="text-align: center;" v-for="(item,index) in member_info.rights">
                  <img src="@/assets/images/home/yunli/zhekou.png" alt="" style="width: 80px;height: 80px;">
                  <div style="font-size: 18px;font-weight: 400;color: #3f4157;">{{item.label}}</div>
                </el-col>
                <!--<el-col :span="4" style="text-align: center;">-->
                <!--  <img src="@/assets/images/home/yunli/zhekou.png" alt="" style="width: 80px;height: 80px;">-->
                <!--  <div style="font-size: 18px;font-weight: 400;color: #3f4157;">7.8折扣</div>-->
                <!--</el-col>-->
                <!--<el-col :span="4" style="text-align: center;">-->
                <!--  <img src="@/assets/images/home/yunli/zhekou.png" alt="" style="width: 80px;height: 80px;">-->
                <!--  <div style="font-size: 18px;font-weight: 400;color: #3f4157;">7.8折扣</div>-->
                <!--</el-col>-->
                <!--<el-col :span="4" style="text-align: center;">-->
                <!--  <img src="@/assets/images/home/yunli/zhekou.png" alt="" style="width: 80px;height: 80px;">-->
                <!--  <div style="font-size: 18px;font-weight: 400;color: #3f4157;">7.8折扣</div>-->
                <!--</el-col>-->
                <!--<el-col :span="4" style="text-align: center;">-->
                <!--  <img src="@/assets/images/home/yunli/zhekou.png" alt="" style="width: 80px;height: 80px;">-->
                <!--  <div style="font-size: 18px;font-weight: 400;color: #3f4157;">7.8折扣</div>-->
                <!--</el-col>-->
                <!--<el-col :span="4" style="text-align: center;">-->
                <!--  <img src="@/assets/images/home/yunli/zhekou.png" alt="" style="width: 80px;height: 80px;">-->
                <!--  <div style="font-size: 18px;font-weight: 400;color: #3f4157;">7.8折扣</div>-->
                <!--</el-col>-->
              </el-row>
            </div>

          </div>
        </div>
      </div>

    </div>


  </div>
</template>
<script>
import {member_info} from "@/api/yunli";
export default {
  data(){
    return{
      img:require('../../assets/images/home/yunli/bg.png'),

      form:{},

      member_info:{
        member_name:''
      },
    }
  },

  mounted() {
    // 调用获取会员信息接口
    this.getmember_info();
  },

  methods:{
    //点击返回按钮
    returnPage(){
      this.$router.go(-1);
    },

    // 获取会员信息
    getmember_info(){
      let id = JSON.parse(localStorage.getItem('userInfo')).id
      member_info(id).then(res => {
        console.log(res);
        if(res.data.length === 0){
          this.member_info.member_name = '注册会员'
        }else {
          this.member_info = res.data;
        }
      })
    },

    //跳转升级会员
    ToupgradeMember(){
      this.$router.push({
        path:'/upgradeMember',
        query:{
          href: window.location.href
        }
      })
    },

  },

}
</script>

<style lang="scss" scoped>
.bg{
  width: 60%;
  margin: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 20px;
  padding: 20px;
}
</style>
